/* eslint-disable quotes */
/* eslint-disable semi */
import React from "react"

import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "../partial/Header"
import Footer from "../partial/Footer"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div
      className="font-Nunito min-h-screen overflow-scroll flex flex-col snap-y snap-mandatory"
      itemScope
      itemType="https://schema.org/Organization"
    >
      <Header siteTitle={data.site.siteMetadata?.title || "Title"} />
      <main className="flex-grow">{children}</main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
