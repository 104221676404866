import React from 'react';
import { Popup, Slider } from 'react-typeform-embed';

const TypeForm = ({
  type, formID, children, ...other
}) => {
  const sliderForm = (
    <Slider id={formID} {...other}>
      {children}
    </Slider>
  );

  const popupForm = (
    <Popup id={formID} {...other}>
      {children}
    </Popup>
  );

  return (
    <>
      {type === 'slider' && sliderForm}
      {type === 'popup' && popupForm}
    </>

  );
};

export default TypeForm;
