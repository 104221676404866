import React from 'react';

import CustomerSection from './Customer';
import MerchantSection from './Merchant';

const serviceSection = () => (
  <section className="bg-background_secondary pb-sm pt-sm md:pb-xl md:pt-xl" id="feature" itemScope itemType="https://schema.org/Service">
    <CustomerSection />
    <MerchantSection />
  </section>
);

export default serviceSection;
