/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import Play from '../icons/Play';
import Store from '../icons/Apple';
import CloseIcon from '../icons/CloseArrow';

const Button = () => {
  const [showModal, setShowModal] = React.useState(false);

  const buttonData = [
    {
      index: 1,
      icon: (
        <Store />
      ),
      name: 'APP STORE',
      subName: 'Download on the',
    },
    {
      index: 2,
      icon: (
        <Play />
      ),
      name: 'GOOGLE APP',
      subName: 'Get it on',
    },
  ];

  return (
    <div className="md:justify-start mt-20px flex sm:justify-center lg:justify-start">
      {buttonData.map((item) => (
        // eslint-disable-next-line react/jsx-key
        <div className="mr-4">
          <div
            className="cursor-pointer rounded-full shadow lg:w-44 md:w-40 mr-3 w-full"
            onClick={() => setShowModal(true)}
            key={item.index}
          >
            <div
              className="h-[45px] bg-heading text-secondary w-full flex items-center justify-center px-0.1n py-2
                border border-transparent rounded-full hover:bg-white hover:text-primary md:px-5 md:py-3"
            >
              {item.icon}
              <span className="mx-1.5 block">
                <div className="font-light text-8md mb-8px lg:text-8md">{item.subName}</div>
                <div className="font-medium text-12md lg:text-7md">{item.name}</div>
              </span>
            </div>
          </div>
          {showModal ? (
            <>
              <div
                className="md:w-[30%] marker:justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
              >
                <div className="relative w-auto my-6 mx-auto max-w-3xl px-[30px] md:px-0">
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    <div className="flex items-center justify-end border-b border-solid border-slate-200 rounded-b">
                      <button
                        className="cursor-pointer background-transparent px-6 py-2 text-sm outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => setShowModal(false)}
                      >
                        <CloseIcon styles="w-4 text-gray-400" />
                      </button>
                    </div>
                    <div className="relative p-6 flex-auto">
                      <p className="my-4 text-slate-500 text-lg leading-relaxed">
                        Waychit will be available in the different stores soon.
                        Check back regularly!
                      </p>
                      <button
                        className="text-7md rounded-full curcor-pointer bg-primary text-white active:bg-emerald-600 font-bold uppercase text-sm px-3 py-3 md:py-3 md:px-6 xl:px-6 shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => {
                          window.open('https://fixvizkdo7f.typeform.com/to/r70OXZv3');
                          setShowModal(false);
                        }}
                      >
                        Waychit Survey
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      ))}
    </div>
  );
};
export default Button;
