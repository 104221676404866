import React from 'react';

const StoreIcon = () => (
  <svg
    width="29"
    height="28"
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.4525 7.4845C17.2475 7.4845 16.3156 8.53668 14.78 8.53668C13.2055 8.53668 12.0046 7.49215 10.0938 7.49215C8.22349 7.49215 6.22904 8.63403 4.96248 10.5793C3.18404 13.3224 3.48592 18.4887 6.36631 22.89C7.39662 24.4655 8.77256 26.2325 10.5772 26.2516H10.6101C12.1785 26.2516 12.6444 25.2246 14.8029 25.2125H14.8358C16.962 25.2125 17.3886 26.2456 18.9504 26.2456H18.9833C20.7879 26.2265 22.2377 24.2686 23.268 22.6991C24.0096 21.5704 24.2852 21.0038 24.854 19.7268C20.6873 18.1453 20.0179 12.2385 24.1387 9.97387C22.8808 8.39887 21.1133 7.48668 19.447 7.48668L19.4525 7.4845Z"
      fill="white"
    />
    <path
      d="M18.9664 1.75C17.6539 1.83914 16.1226 2.67477 15.2258 3.76578C14.412 4.75453 13.7426 6.22125 14.0051 7.64367H14.1101C15.508 7.64367 16.9386 6.80203 17.7742 5.72359C18.5792 4.69711 19.1895 3.24242 18.9664 1.75Z"
      fill="white"
    />
  </svg>
);
export default StoreIcon;
