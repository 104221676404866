import React from 'react';
import TypeForm from './TypeForm';

const Button = ({ coupon }) => (
  <div className="md:justify-start mt-20px flex sm:justify-center lg:justify-start">
    <div className="cursor-pointer">
      <TypeForm type="slider" formID={coupon ? 'EDxbpAi2' : 'r70OXZv3'}>
        <button type="button" className="btn-sm text-primary hover:text-gray-800 flex items-center font-medium">
          <span>Learn More</span>
          <svg className="w-3 h-3 fill-current text-primary flex-shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fillRule="nonzero" />
          </svg>
        </button>
        {' '}

      </TypeForm>
    </div>
  </div>
);
export default Button;
