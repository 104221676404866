/* eslint-disable semi */
/* eslint-disable quotes */
import React, { useEffect } from "react"

import AOS from "aos"
import "aos/dist/aos.css"
import Seo from "../components/Seo"
import Faq from "../components/Faq"
import About from "../components/About"
import Coupon from "../components/Coupon"
import Layout from "../components/Layout"
import Process from "../components/Process"
import CalltoAction from "../components/Cta"
import Services from "../components/Services"
import HeroSection from "../components/Herosection"

const IndexPage = () => {
  useEffect(() => {
    AOS.init({
      disable: "mobile",
      duration: 1200,
      once: true,
      mirror: false,
      easing: "ease-out-cubic",
    })
  })

  return (
    <Layout>
      <Seo
        title="Your ultimate resource for hassle-free payments in The Gambia!"
        homePage
      />
      <HeroSection />
      <Services />
      <Coupon />
      <About />
      <Process />
      <Faq />
      <CalltoAction />
    </Layout>
  )
}
export default IndexPage
