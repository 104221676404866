import React from 'react';

import DashIcon from '../icons/Dash';
import ProcessArrowIcon from '../icons/ProcessArrow';

const ProcessSection = () => {
  const [openTab, setOpenTab] = React.useState(1);

  return (
    <section id="process" className="snap-start pb-sm pt-sm md:pb-xl md:pt-xl px-5">
      <div className="container mx-auto md:max-w-md lg:max-w-lg 2xl:max-w-2xl 3xl:max-w-3xl max-w-1/4">
        <div className="md:text-center">
          <div className="inline-flex items-center">
            <DashIcon className="relative left-[42%]" />
            <div className="text-primary block text-sm tracking-1 font-light w-full">How it works</div>
          </div>
          <h2 className="relative md:text-3xl text-9md font-bold tracking-tight text-heading">
            Ready to try
            {' '}
            <span className="text-heading_secondary">
              waychit
            </span>
            ?
          </h2>
        </div>
        <div className="flex flex-wrap md:mt-8 mt-6">
          <div className="w-full mx-auto">
            <ul
              role="tablist"
              aria-owns="#link1 #link2"
              className="2xl:w-30% lg:w-45% w-[80%] md:w-[45%] m-0 md:m-auto flex space-x-1 bg-background_secondary rounded-full"
            >
              <li className="cursor-pointer -mb-px flex-auto text-center">
                <a
                  className={
                    `ml-0 text-center rounded-full text-heading text-7md md:text-2md 
                    font-medium md:px-5 md:py-3 px-2 py-2 block leading-normal ${
                   openTab === 1
                    ? 'text-white bg-heading_secondary'
                    : 'text-heading hover:bg-white/[0.12]'}`
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(1);
                  }}
                  role="tab"
                  href="#link1"
                  data-toggle="tab"
                  aria-label="Open Item"
                  aria-selected="true"
                >
                  As Customers
                </a>
              </li>
              <li className="cursor-pointer -mb-px md:ml-90 flex-auto text-center">
                <a
                  className={
                    `rounded-full text-7md md:text-2md font-medium
                     md:px-5 md:py-3 px-2 py-2  block leading-normal ${
                   openTab === 2
                    ? 'text-white bg-heading_secondary'
                    : 'text-heading hover:bg-white/[0.12]'}`
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(2);
                  }}
                  role="tab"
                  href="#link2"
                  data-toggle="tab"
                  aria-label="Open Item"
                  aria-selected="true"
                >
                  As  Merchants
                </a>
              </li>
            </ul>
            <div className="relative flex flex-col min-w-0 break-words w-full mt-8">
              <div className="px-4 py-5 flex-auto">
                <div className="tab-content tab-space">
                  <div className={openTab === 1 ? 'block' : 'hidden'} id="link1">
                    <div className="grid grid-cols-1 gap-16 md:grid-cols-5 md:gap-2 items-center">
                      <div className="flex flex-col relative before:absolute before:contents before:top-0 before:left-75 before:bg-no-repeat before:bg-center before:w-215px before:h-60px odd:last:first:before:bg-arrow-odd odd:last:before:bg-arrow-even odd:last:before:top-17">
                        <div className="w-36px h-36px bg-black flex items-center mb-30px text-md font-bold justify-center text-secondary shadow rounded-full">1</div>
                        <div className="w-full flex flex-col mt-5px">
                          <h6 className="mb-15px font-semibold md:text-6md text-2md">Register</h6>
                          <p className="text-text text-16md md:text-2md font-normal">Download the customer app and create account.</p>
                        </div>
                      </div>
                      <div className="align-middle hidden md:contents">
                        <ProcessArrowIcon />
                      </div>
                      <div className="flex flex-col relative before:absolute before:contents before:top-0 before:left-75 before:bg-no-repeat before:bg-center before:w-215px before:h-60px odd:last:first:before:bg-arrow-odd odd:last:before:bg-arrow-even odd:last:before:top-17">
                        <div className="w-36px h-36px bg-black flex items-center mb-30px text-md font-bold justify-center text-secondary shadow rounded-full">2</div>
                        <div className="w-full flex flex-col mt-5px">
                          <h6 className="mb-15px font-semibold md:text-6md text-2md">Link Payment</h6>
                          <p className="text-text text-16md md:text-2md font-normal">
                            Link your favorite payment account(s). E.g. bank, mobile money.
                          </p>
                        </div>
                      </div>
                      <div className="align-middle hidden md:contents">
                        <ProcessArrowIcon />
                      </div>
                      <div className="flex flex-col relative before:absolute before:contents before:top-0 before:left-75 before:bg-no-repeat before:bg-center before:w-215px before:h-60px odd:last:first:before:bg-arrow-odd odd:last:before:bg-arrow-even odd:last:before:top-17">
                        <div className="w-36px h-36px bg-black flex items-center mb-30px text-md font-bold justify-center text-secondary shadow rounded-full">3</div>
                        <div className="w-full flex flex-col mt-5px">
                          <h6 className="mb-15px font-semibold md:text-6md text-2md">Scan and pay</h6>
                          <p className="text-text text-16md md:text-2md font-normal">
                            Scan a merchant-generated payment request to pay.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={openTab === 2 ? 'block' : 'hidden'} id="link2">
                    <div className="grid grid-cols-1 gap-16 md:grid-cols-5 md:gap-2 items-center">
                      <div className="flex flex-col relative before:absolute before:contents before:top-0 before:left-75 before:bg-no-repeat before:bg-center before:w-215px before:h-60px odd:last:first:before:bg-arrow-odd odd:last:before:bg-arrow-even odd:last:before:top-17">
                        <div className="w-36px h-36px bg-black flex items-center mb-30px text-md font-bold justify-center text-secondary shadow rounded-full">1</div>
                        <div className="w-full flex flex-col mt-5px">
                          <h6 className="mb-15px font-semibold md:text-6md text-2md">Register</h6>
                          <p className="text-text text-16md md:text-2md font-normal">Download the merchant app and create account.</p>
                        </div>
                      </div>
                      <div className="align-middle hidden md:contents">
                        <ProcessArrowIcon />
                      </div>
                      <div className="flex flex-col relative before:absolute before:contents before:top-0 before:left-75 before:bg-no-repeat before:bg-center before:w-215px before:h-60px odd:last:first:before:bg-arrow-odd odd:last:before:bg-arrow-even odd:last:before:top-17">
                        <div className="w-36px h-36px bg-black flex items-center mb-30px text-md font-bold justify-center text-secondary shadow rounded-full">2</div>
                        <div className="w-full flex flex-col mt-5px">
                          <h6 className="mb-15px font-semibold md:text-6md text-2md">Accept Payment</h6>
                          <p className="text-text text-16md md:text-2md font-normal">
                            Enter transaction details and generate payment
                            request for customer to pay.
                          </p>
                        </div>
                      </div>
                      <div className="align-middle hidden md:contents">
                        <ProcessArrowIcon />
                      </div>
                      <div className="flex flex-col relative before:absolute before:contents before:top-0 before:left-75 before:bg-no-repeat before:bg-center before:w-215px before:h-60px odd:last:first:before:bg-arrow-odd odd:last:before:bg-arrow-even odd:last:before:top-17">
                        <div className="w-36px h-36px bg-black flex items-center mb-30px text-md font-bold justify-center text-secondary shadow rounded-full">3</div>
                        <div className="w-full flex flex-col mt-5px">
                          <h6 className="mb-15px font-semibold md:text-6md text-2md">Pay Outs</h6>
                          <p className="text-text text-16md md:text-2md font-normal">
                            Payment of funds into mobile wallet / bank account.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
};

export default ProcessSection;
