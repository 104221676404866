import React from 'react';

import Button from '../utils/Button';
import ctaGif from '../images/mockup/WaychitGif.gif';

const CTASection = () => (
  <section id="contact" className="bg-primary bg-split-white-primary lg:bg-split-white-primary md:bg-none pb-[20px] pt-sm md:pb-xl md:pt-xl overflow-hidden px-5 snap-start">
    <div className="container mx-auto md:max-w-md lg:max-w-lg 2xl:max-w-2xl 3xl:max-w-3xl max-w-1/4">
      <div className="grid grid-cols-1 gap-16 md:gap-8 md:grid-cols-1 2xl:grid-cols-2 lg:grid-cols-2 3xl:grid-cols-2 3xl:gap-80 items-center">
        <div className="order-last md:order-last 2xl:order-none 3xl:order-none lg:order-none lg:mt-44 w-full max-w-full md:mt-10 pb-xl md:pb-0">
          <h2 className="relative md:text-3xl text-9md font-bold tracking-tight text-secondary">
            Enjoy a better payment experience
          </h2>
          <p className="md:mt-8 mt-4 text-secondary pr-0 text-16md md:text-2md font-normal">
            You can download both the merchant and customer app in appstore or playstore.
          </p>
          <Button />
        </div>
        <div className="cursor-auto w-full">
          <img src={ctaGif} alt="Waychit App" loading="lazy" className="w-[587px] h-auto md:w-full md:h-full lg:w-[587px] lg:h-[537px] 2xl:w-[587px] 2xl:h-[537px] 3xl:w-[587px] 3xl:h-[537px]" />
        </div>
      </div>
    </div>
  </section>
);

export default CTASection;
