import React from 'react';

const ExpandArrowBtn = () => (
  <svg
    viewBox="0 0 24 24"
    width="16"
  >
    <polyline
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeMiterlimit="10"
      points="2,7 12,17 22,7"
      strokeLinejoin="round"
    />
  </svg>

);
export default ExpandArrowBtn;
