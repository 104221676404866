import React from 'react';

import app from '../icons/app.svg';
import DashIcon from '../icons/Dash';
import clock from '../icons/clock.svg';
import safety from '../icons/safety.svg';
import secure from '../icons/secure.svg';

const contentData = [
  {
    index: 1,
    icon: safety,
    title: 'Pay safely without your wallet',
    description: 'Hassle-free contactless payments by scanning a QR code.',
  },
  {
    index: 2,
    icon: app,
    title: 'All In One App',
    description: 'Tools to help you get paid quickly, deliver better client service, and improve cash flow.',
  },
  {
    index: 3,
    icon: secure,
    title: 'Highly Secured',
    description: ' Safeguard your money with our supreme security standards.',
  },
  {
    index: 4,
    icon: clock,
    title: '24/7 Support',
    description: 'Our support team is on hand, 24/7, to help answer your questions.',
  },
];

const aboutSection = () => (
  <section className="snap-start pt-xl pb-xl px-5 bg-background_secondary" id="about">
    <div className="container mx-auto md:max-w-md lg:max-w-lg 2xl:max-w-2xl 3xl:max-w-3xl max-w-1/4">
      <div className="flex flex-wrap items-center">
        <div className="md:pr-12 mx-auto lg:w-2/5 w-full md:mt-8">
          <div className="flex mb-4 items-center">
            <DashIcon />
            <div className="text-primary block text-sm tracking-1 font-light w-full">Why Waychit </div>
          </div>
          <h2 className="pb-7 md:pb-0 md:text-3xl text-9md font-bold tracking-tight text-heading relative">
            Waychit
            {' '}
            <span className="text-heading_secondary">
              understands
            </span>
            {' '}
            all your needs
          </h2>
        </div>
        <div className="lg:w-3/5 w-full flex flex-wrap md:flex-wrap">
          {contentData.map((item) => (
            <div
              key={item.index}
              className="md:w-1/2 w-full block md:p-6 py-4 focus:outline-none
                hover:shadow-lg hover:rounded-xl focus:shadow-lg focus:rounded-xl"
            >
              <span className="inline-block">
                <img className="w-8 h-8" src={item.icon} alt={item.icon} />
              </span>
              <h6 className="mt-3 font-semibold text-6md">{item.title}</h6>
              <p className="block mt-3 text-text text-16md md:text-2md font-normal">
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  </section>
);

export default aboutSection;
