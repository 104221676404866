import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Pattern from '../icons/Pattern';
import Button from '../utils/Button-1';
import DashIcon from '../icons/Dash';

const MerchantSection = () => {
  const data = useStaticQuery(graphql`
  {
    frame1: file (relativePath: { eq: "mockup/merchantBg.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED )
      }
    },
    frame2: file (relativePath: { eq: "mockup/payment.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED )
      }
    },
    frame3: file (relativePath: { eq: "mockup/scanPay.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED )
      }
    },
  }
`);
  return (
    <section className="pt-sm md:pt-xl px-5 snap-start">
      <div className="container mx-auto max-w-1/4 md:max-w-md lg:max-w-lg 2xl:max-w-2xl 3xl:max-w-3xl">
        <div className="grid grid-cols-1 gap-16 md:gap-20 md:grid-cols-1 2xl:grid-cols-2 lg:grid-cols-2 3xl:gap-80 items-center">
          <div className="w-full max-w-full mt-10 sm:mt-0">
            <div className="flex mb-4 items-center">
              <DashIcon />
              <div className="text-primary block text-sm tracking-1 font-medium w-full" itemProp="name">Waychit For Merchants</div>
            </div>
            <h2 className="relative md:text-3xl text-9md font-bold tracking-tight text-heading">
              Accept payment
              {' '}
              <span className="text-heading_secondary">
                anytime
              </span>
              {' '}
              and
              {' '}
              <span className="text-heading_secondary">
                anywhere
              </span>
            </h2>
            <p className="md:mt-8 mt-4 text-text pr-0 text-16md md:text-2md" itemProp="description">
              We take you where the customers are, and moving your money is a click away.
              Sell in-person, online, or from anywhere.
            </p>
            <p className="mt-2.5 text-text pr-0 text-2md">
              Take payments from customers, clients, and more.
            </p>
            <Button />
          </div>
          <div className="w-full">
            <div className="relative inline-flex flex-col md:ml-21% lg:ml-0">
              <GatsbyImage className="z-20 md:max-w-none mx-auto rounded w-[75%] md:w-full lg:w-[75%]" image={data.frame1.childImageSharp.gatsbyImageData} alt="waychit merchant" />
              <div className="z-30 absolute translate-x-[135px] mobile:translate-x-[170px] xs:translate-x-[120%] 360:translate-x-[130%] 390:translate-x-[120%] xr:translate-x-[130%] mobile-md:translate-y-[125%] mobile-md:translate-x-[140%] md:translate-x-[90%] lg:translate-x-[-50px] lg:translate-y-[102%] 2xl:translate-x-[-27%] 2xl:translate-y-[60%] translate-y-[75%] md:translate-y-[40%] transform">
                <GatsbyImage className="md:max-w-none w-[135px] mobile-md:w-[160px] xs:w-[160px] 390:w-[155px] xr:w-[160px] md:w-[260px] lg:w-[200px] 2xl:w-[260px]" image={data.frame2.childImageSharp.gatsbyImageData} alt="Waychipt payment" />
              </div>
              <div className="z-30 absolute translate-x-[-2px] mobile-md:translate-y-[90%] mobile-md:translate-x-[10%] md:translate-x-[-68px] lg:translate-x-[77%] lg:translate-y-[25%] 2xl:translate-x-[100%] 2xl:translate-y-[25%] translate-y-[110%] md:translate-y-[80%] transform">
                <GatsbyImage className="md:max-w-none w-[135px] xs:w-[160px] mobile-md:w-[160px] md:w-[210px] lg:w-[225px] 2xl:w-[250px]" image={data.frame3.childImageSharp.gatsbyImageData} alt="Waychipt service" />
              </div>
              <span className="absolute translate-y-[10%] right-[-13px] md:right-[-79px] z-[-1]">
                <Pattern className="w-[125px] h-[158px]" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MerchantSection;
