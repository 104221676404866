import React from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';

import Pattern from '../icons/Pattern';
import TypeForm from '../utils/TypeForm';

const HeroSection = () => {
  const data = useStaticQuery(graphql`
  {
    heroImage: file (relativePath: { eq: "mockup/heroImage-1.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED )
      }
    },
  }
`);
  return (
    <section className="snap-start bg-white pb-sm pt-sm md:pb-xl md:pt-xl overflow-hidden relative px-5" id="home">
      <div className="container mx-auto md:max-w-md lg:max-w-lg 2xl:max-w-2xl max-w-1/4 3xl:max-w-3xl">
        <div className="grid grid-cols-1 md:grid-cols-1 md:gap-20 2xl:grid-cols-2 lg:grid-cols-2 gap-16 items-center justify-items-center">
          <div className="w-full max-w-1/4 mt-10 md:max-w-768">
            <h2 className="text-9md md:text-3xl font-extrabold tracking-tight text-heading sm:text-4xl">
              Shaping the future of payments with
              {' '}
              <span className="text-heading_secondary" itemProp="name">
                Waychit
              </span>
            </h2>
            <p className="mt-4 text-16md md:text-2md text-text pr-0 sm:mx-auto md:mt-8 sm:mt-8 font-normal">
              Pay, get paid and shop with Waychit — a new way to make easy,
              {' '}
              reliable, and secure payments.
            </p>
            <div
              data-aos="zoom-y-out"
              data-aos-delay="300"
              className="mt-4 transform transition duration-500 hover:scale-110"
            >
              <TypeForm type="slider" formID="r70OXZv3">
                <button type="button" aria-label="form popup" className="cursor-pointer xl:px-10 md:px-10 md:py-4 px-6 py-2 bg-heading_secondary inline-flex rounded-full bg-opacity-90 items-center justify-center font-medium text-white transition-colors duration-150 transform">
                  GET STARTED
                </button>
              </TypeForm>

            </div>
          </div>
          <div className="relative inline-flex flex-col mb-4 h-full object-contain">
            <div>
              <GatsbyImage image={data.heroImage.childImageSharp.gatsbyImageData} alt="hero-pattern" />
            </div>
            <span className="absolute translate-y-[240%] translate-x-[415%] z-[-1]">
              <Pattern className="w-[125px] h-[158px]" />
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
