/* eslint-disable max-len */
import React from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';

import Pattern from '../icons/Pattern';
import Button from '../utils/Button-1';
import DashIcon from '../icons/Dash';

const CustomerSection = () => {
  const data = useStaticQuery(graphql`
  {
    frame1: file (relativePath: { eq: "mockup/customerBg.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED )
      }
    },
    frame2: file (relativePath: { eq: "mockup/menue.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED )
      }
    },
    frame3: file (relativePath: { eq: "mockup/CustomerReceipt.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED )
      }
    },
    frame4: file (relativePath: { eq: "mockup/scanTopay.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED )
      }
    },
  }
`);

  return (
    <section className="px-5 overflow-hidden relative snap-start">
      <div className="container mx-auto max-w-1/4 md:max-w-md lg:max-w-lg 2xl:max-w-2xl 3xl:max-w-3xl">
        <div className="grid grid-cols-1 gap-16 md:gap-20 md:grid-cols-1 2xl:grid-cols-2 lg:grid-cols-2 3xl:gap-80 items-center">
          <div className="order-last md:order-last 2xl:order-none lg:order-none cursor-auto w-full">
            <div className="relative inline-flex flex-col md:ml-21% lg:ml-0">
              <GatsbyImage className="md:max-w-none relative z-20 mx-auto rounded w-[75%] md:w-full lg:w-[75%]" image={data.frame1.childImageSharp.gatsbyImageData} alt="customer" />
              <div className="z-30 absolute transform translate-y-[32%] md:translate-y-20">
                <GatsbyImage className="md:max-w-none" image={data.frame2.childImageSharp.gatsbyImageData} alt="menue" />
              </div>
              <div className="z-30 absolute translate-y-[70%] 414:translate-y-[85%] mobile-md:translate-y-[95%] translate-x-0 md:translate-x-[-30%] lg:translate-x-[-10%] lg:translate-y-[80%] transform">
                <GatsbyImage className="w-[60%] md:w-[75%]" image={data.frame3.childImageSharp.gatsbyImageData} alt="coupon" />
              </div>
              <div className="z-30 absolute transform translate-y-[240%] translate-x-[118%] lg:translate-x-[160%] lg:translate-y-[260%] md:translate-x-[174%] mobile:translate-x-[145%] 360:translate-x-[145%] xr:translate-x-[180%] xs:translate-x-[160%] mobile:translate-y-[312%] mobile-md:translate-x-[183%]">
                <GatsbyImage className="w-[60%] md:w-full" image={data.frame4.childImageSharp.gatsbyImageData} alt="scan" />
              </div>
              <span className="absolute translate-y-[80%] translate-x-[-50%] z-[-1]">
                <Pattern className="w-[125px] h-[158px]" />
              </span>
            </div>
          </div>
          <div className="w-full max-w-full md:mt-10">
            <div className="flex mb-4 items-center">
              <DashIcon />
              <div className="text-primary block text-sm tracking-1 font-medium w-full" itemProp="name">Waychit For Customers</div>
            </div>
            <h2 className="relative text-9md md:text-3xl font-bold tracking-tight text-heading">
              Smart payments smart
              {' '}
              <span className="text-heading_secondary">
                Lifestyle
              </span>
            </h2>
            <p className="md:mt-8 mt-4 text-text pr-0 text-16md md:text-2md font-normal" itemProp="description">
              Buy fuel, cash power, airtime, and food at the restaurant of your choice.
              We offer a variety of digital payment options to add simplicity to your life.
            </p>
            <Button />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CustomerSection;
