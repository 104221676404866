import React from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';

import Button from '../utils/Button-1';
import DashIcon from '../icons/Dash';

const CouponSection = () => {
  const data = useStaticQuery(graphql`
    {
      frame1: file (relativePath: { eq: "mockup/couponBg.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED )
        }
      },
      frame2: file (relativePath: { eq: "mockup/Receipt.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED )
        }
      },
    }
  `);
  return (
    <section id="coupon" className="bg-white pb-sm pt-sm md:pb-xl md:pt-xl px-5 snap-start">
      <div className="container mx-auto md:max-w-md lg:max-w-lg 2xl:max-w-2xl 3xl:max-w-3xl max-w-1/4">
        <div className="grid grid-cols-1 gap-16 md:gap-20 md:grid-cols-1 2xl:grid-cols-2 lg:grid-cols-2  3xl:gap-80 items-center">
          <div className="order-last md:order-last 2xl:order-none lg:order-none cursor-auto w-full">
            <div className="relative inline-flex flex-col md:ml-21% 2xl:ml-0 lg:ml-0">
              <GatsbyImage
                className="z-20 md:max-w-none mx-auto rounded w-[75%] md:w-full lg:w-[75%]"
                image={data.frame1.childImageSharp.gatsbyImageData}
                alt="coupon"
              />
              <div className="z-30 absolute translate-x-[40%] xs:translate-x-[78%] 360:translate-x-[54%] 390:translate-x-[70%] xr:translate-x-[85%] translate-y-[68%] mobile:translate-x-[60%] mobile:translate-y-[90%] mobile-md:translate-x-[90%] mobile-md:translate-y-[115%] md:translate-x-[40%] transform">
                <GatsbyImage
                  className="md:max-w-none w-[200px] md:w-[350px] lg:w-[285px]"
                  image={data.frame2.childImageSharp.gatsbyImageData}
                  alt="Waychipt receipt"
                />
              </div>
            </div>
          </div>
          <div className="w-full max-w-full md:pr-15px">
            <div className="flex mb-4 items-center">
              <DashIcon />
              <div className="text-primary block text-sm tracking-1 font-medium w-full">Coupons</div>
            </div>
            <h2 className="relative md:text-3xl text-9md font-bold tracking-tight text-heading">
              <span className="text-heading_secondary">
                Buy. Use. Share
              </span>
              {' '}
              coupons with waychit
            </h2>
            <p className="md:mt-8 mt-4 text-text pr-0 text-16md md:text-2md font-normal">
              With Waychit, you can enjoy a seamless refueling experience by buying
              gas-station-agnostic coupons with no extra fees.
              You can redeem a coupon at any Waychit-enabled gas station in The Gambia.
            </p>
            <p className="mt-2.5 text-text pr-0 text-2md font-normal">
              Share purchased coupons with your loved ones on Waychit
            </p>
            <Button coupon />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CouponSection;
