import React from 'react';

const ProcessArrowIcon = () => (
  <svg
    width="121"
    height="3"
    viewBox="0 0 121 3"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.432617"
      y="0.225098"
      width="6.54545"
      height="1.98915"
      fill="black"
      fillOpacity="0.8"
    />
    <rect
      x="113.888"
      y="0.225098"
      width="6.54545"
      height="1.98915"
      fill="black"
      fillOpacity="0.8"
    />
    <rect
      x="15.7051"
      y="0.225098"
      width="10.9091"
      height="1.98915"
      fill="black"
      fillOpacity="0.8"
    />
    <rect
      x="35.3418"
      y="0.225098"
      width="10.9091"
      height="1.98915"
      fill="black"
      fillOpacity="0.8"
    />
    <rect
      x="54.9785"
      y="0.225098"
      width="10.9091"
      height="1.98915"
      fill="black"
      fillOpacity="0.8"
    />
    <rect
      x="74.6143"
      y="0.225098"
      width="10.9091"
      height="1.98915"
      fill="black"
      fillOpacity="0.8"
    />
    <rect
      x="94.251"
      y="0.225098"
      width="10.9091"
      height="1.98915"
      fill="black"
      fillOpacity="0.8"
    />
  </svg>
);
export default ProcessArrowIcon;
