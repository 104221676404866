/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';

import ExpandArrowBtn from '../icons/ExpandArrow';

const FaqSection = () => {
  const data = useStaticQuery(graphql`
  {
    file(relativePath: {eq: "mockup/faq-bg.png"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED )
      }
    }
  }
`);
  const Item = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
      <div className="border rounded shadow-sm" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
        <button
          type="button"
          aria-label="Open item"
          title="Faq"
          className="cursor-auto flex items-center justify-between w-full p-4 focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
        >
          <p className="text-lg font-medium text-heading text-left" itemProp="name">{title}</p>
          <ExpandArrowBtn
            className={`ml-md w-3 text-gray-600 transform transition-transform duration-200 ${
            isOpen ? 'rotate-180' : ''
          }`}
          />
        </button>
        <div className={`p-4 pt-0 ${!isOpen ? 'hidden' : ''}`} itemProp="acceptedAnswer" itemScope itemType="https://schema.org/Answer">
          <p className="text-text font-normal text-16md md:text-2md-1" itemProp="text">
            {children}
          </p>
        </div>
      </div>
    );
  };
  return (
    <section className="bg-background_secondary pb-sm pt-sm md:pb-xl md:pt-xl px-5" itemScope itemType="https://schema.org/FAQPage">
      <div className="container mx-auto md:max-w-md lg:max-w-lg 2xl:max-w-2xl 3xl:max-w-3xl max-w-1/4">
        <div className="grid grid-cols-1 gap-16 md:gap-8 md:grid-cols-2 items-center">
          <div className="cursor-auto w-full">
            <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} alt="waychit frequently asked" />
          </div>
          <div className="w-full">
            <div className="space-y-4">
              <h2 className="mb-md relative md:text-3xl text-9md font-bold tracking-tight text-heading sm:text-4xl" itemProp="name">FAQ&apos;s</h2>
              <Item title="How do I reset my pin?">
                Our customer support team can help you reset any pin.
                Please have your phone handy and use a different phone to place the call.
              </Item>
              <Item title="How to activate a Wacyhit account as a consumer?">
                Download the Waychit customer app from the store, and link a
                payment method, then start making payments, voila!
              </Item>
              <Item title="How can I register on Waychit as a merchant?">
                Download the merchant app from the store, log in, start receiving payments
              </Item>
              <Item title="What can I do with Waychit?">
                <ul itemProp="text">
                  <li>
                    <span className="flex">
                      <svg className="w-2 text-text1 mx-2" viewBox="0 0 8 8" fill="currentColor">
                        <circle cx="4" cy="4" r="3" />
                      </svg>
                      Make instant payments.
                    </span>
                  </li>
                  <li>
                    <span className="flex">
                      <svg className="w-2 text-text1 mx-2" viewBox="0 0 8 8" fill="currentColor">
                        <circle cx="4" cy="4" r="3" />
                      </svg>
                      Pay your bills and buy airtime for free.
                    </span>
                  </li>
                  <li>
                    <span className="flex">
                      <svg className="w-2 text-text1 mx-2" viewBox="0 0 8 8" fill="currentColor">
                        <circle cx="4" cy="4" r="3" />
                      </svg>
                      Buy coupons redeem-able at any station
                    </span>
                  </li>
                  <li>
                    <span className="flex">
                      <svg className="w-2 text-text1 mx-2" viewBox="0 0 8 8" fill="currentColor">
                        <circle cx="4" cy="4" r="3" />
                      </svg>
                      Pay for food at your favorite restaurants
                    </span>
                  </li>
                </ul>
              </Item>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqSection;
